import "./style.css";
import * as THREE from "three";
import * as dat from "lil-gui";
import gsap from "gsap";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import CANNON from "cannon";
import CannonDebugger from "cannon-es-debugger";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

/**
 * Identificação de slide vertical
 */
let logoWondar = document.querySelector("img.logo-wondar");

/**
 * Debug
 */
const gui = new dat.GUI();
gui.hide();
const debugObject = {};

const parameters = {
  materialColor: "#ffeded",
};

gui.addColor(parameters, "materialColor").onChange(() => {
  material.color.set(parameters.materialColor);
  particlesMaterial.color.set(parameters.materialColor);
});

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // console.log("loaded");
    document.getElementById("linguas").classList.remove("d-none");
  },

  // Progress
  () => {
    // console.log("progress");
  }
);

// Textures
const textureLoader = new THREE.TextureLoader(loadingManager);
const gradientTexture = textureLoader.load("textures/gradients/3.jpg");
gradientTexture.magFilter = THREE.NearestFilter;
const backgroundFloor = textureLoader.load("textures/bg-32x32.png");
backgroundFloor.magFilter = THREE.LinearFilter;

const socialEmojis = ["textures/lovesphere.png", "textures/likesphere.png"];

const ptUm = textureLoader.load("textures/textoTVpt1.png")
const ptDois = textureLoader.load("textures/textoTVpt2.png")

const enUm = textureLoader.load("textures/textoTVen1.png")
const enDois = textureLoader.load("textures/textoTVen2.png")

const esUm = textureLoader.load("textures/textoTVes1.png")
const esDois = textureLoader.load("textures/textoTVes2.png")


// Background
scene.background = backgroundFloor;
scene.background.encoding = THREE.sRGBEncoding;

// Environment
const cubeTextureLoader = new THREE.CubeTextureLoader();

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
  "/environmentMaps/0/px.jpg",
  "/environmentMaps/0/nx.jpg",
  "/environmentMaps/0/py.jpg",
  "/environmentMaps/0/ny.jpg",
  "/environmentMaps/0/pz.jpg",
  "/environmentMaps/0/nz.jpg",
]);

environmentMap.encoding = THREE.sRGBEncoding;
scene.environment = environmentMap;
debugObject.envMapIntensity = 1.3;

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshPhysicalMaterial
    ) {
      child.material.envMap = environmentMap;
    }
  });
};

/**
 * RayCaster
 */
const raycaster = new THREE.Raycaster();

/**
 * Mouse
 */
const mouse = new THREE.Vector2();

window.addEventListener("mousemove", (event) => {
  mouse.x = (event.clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = -(event.clientY / sizes.height - 0.5);
});

const groupMeshes = new THREE.Group();

const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);
let modelWondar;
let sliderWondar;
let mixer = null;

dracoLoader.setDecoderPath("/draco/");

gltfLoader.load("/Models/objectsVertical.glb", (model) => {
  modelWondar = model.scene;
  modelWondar.position.y = 0.1;
  updateAllMaterials();
  groupMeshes.add(modelWondar);


  document.getElementById("portugues").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = ptUm
    modelWondar.getObjectByName("textoTv2").material.map = ptDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false
    })

   document.getElementById("espanhol").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = esUm
    modelWondar.getObjectByName("textoTv2").material.map = esDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false

    })

    document.getElementById("ingles").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = enUm
    modelWondar.getObjectByName("textoTv2").material.map = enDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false
    })

  modelWondar.scale.set(0.5, 0.5, 0.5);
  mixer = new THREE.AnimationMixer(modelWondar);
  const action = mixer.clipAction(model.animations[0]);
  const action1 = mixer.clipAction(model.animations[1]);

  gsap.to(modelWondar.getObjectByName("cubo").rotation, {
    duration: 7,
    ease: "power0.inOut",
    y: Math.PI,
    x: Math.PI,
    yoyo: true,
    repeat: -1,
  });
  // action.setLoop(THREE.LoopRepeat)
  // action1.setLoop(THREE.LoopRepeat)
  action.play();
  action1.play();
  // console.log(modelWondar)

  // const rayCastTick = () => 
  // {
   

  //   window.requestAnimationFrame(rayCastTick);
  // };
  // rayCastTick();
  
}
);

window.addEventListener("click", () => {
  raycaster.setFromCamera(mouse, camera);

  const objectsToTest = [
    modelWondar.getObjectByName("bancadaoculos"),
    modelWondar.getObjectByName("poltrona")
  ];
  const intersects = raycaster.intersectObjects(objectsToTest);

  /**
   * Objects Intersect
   */
  if (intersects.length) {
    if (!currentIntersect) {
      //  console.log('mouse enter')
    }

    currentIntersect = intersects[0];
  } else {
    if (currentIntersect) {
      //  console.log('mouse leave')
    }
    currentIntersect = null;
  }
  
  if (currentIntersect) {
    switch (currentIntersect.object) 
    {
      case modelWondar.getObjectByName("bancadaoculos"):
        window.open("https://www.instagram.com/ar/457146478894515/", "_blank");
        break;

      case modelWondar.getObjectByName("poltrona"):
        window.open("https://webar.wond.ar/poltrona", "_blank");
        break;
    }
  }
});

let currentIntersect = null;

//Particles

//Particles
let container;
const particlesData = [];
let positions, colors;
let particles;
let pointCloud;
let particlePositions;
let linesMesh;
const plexusMesh = new THREE.Group();
const maxParticleCount = 1000;
let particleCount = 100;
const r = 400;
const rHalf = r / 2;

const effectController = {
  showDots: true,
  showLines: true,
  minDistance: 80,
  limitConnections: false,
  maxConnections: 10,
  particleCount: 100,
};
const colorHelper = new THREE.Color(0xffffff).multiplyScalar(15);
const corDaLinha = new THREE.Color(0x00d2ff).multiplyScalar(30);
const corDoPonto = new THREE.Color(0x00d2ff).multiplyScalar(30);

const helper = new THREE.BoxHelper(
  new THREE.Mesh(new THREE.BoxGeometry(r, r, r))
);
helper.material.color = colorHelper;
// helper.material.blending = THREE.AdditiveBlending;
helper.material.transparent = false;
// group.add( helper );

const segments = maxParticleCount * maxParticleCount;

positions = new Float32Array(segments * 3);
colors = new Float32Array(segments * 3);

const pMaterial = new THREE.PointsMaterial({
  color: corDoPonto,
  size: 0.04,
  blending: THREE.AdditiveBlending,
  transparent: true,
  sizeAttenuation: true,
});

particles = new THREE.BufferGeometry();
particlePositions = new Float32Array(maxParticleCount * 3);

for (let i = 0; i < maxParticleCount; i++) {
  const x = Math.random() * r - r / 2;
  const y = Math.random() * r - r / 2;
  const z = Math.random() * r - r / 2;

  particlePositions[i * 3] = x;
  particlePositions[i * 3 + 1] = y;
  particlePositions[i * 3 + 2] = z;

  // add it to the geometry
  particlesData.push({
    velocity: new THREE.Vector3(
      -1 + Math.random() * 2,
      -1 + Math.random() * 2,
      -1 + Math.random() * 2
    ),
    numConnections: 0,
  });
}

particles.setDrawRange(0, particleCount);
particles.setAttribute(
  "position",
  new THREE.BufferAttribute(particlePositions, 3).setUsage(
    THREE.DynamicDrawUsage
  )
);

// create the particle system
pointCloud = new THREE.Points(particles, pMaterial);
plexusMesh.add(pointCloud);

const geometry = new THREE.BufferGeometry();

geometry.setAttribute(
  "position",
  new THREE.BufferAttribute(positions, 3).setUsage(THREE.DynamicDrawUsage)
);
geometry.setAttribute(
  "color",
  new THREE.BufferAttribute(colors, 3).setUsage(THREE.DynamicDrawUsage)
);

geometry.computeBoundingSphere();

geometry.setDrawRange(0, 0);
const materialLine = new THREE.LineBasicMaterial({
  vertexColors: true,
  color: corDaLinha,
  // blending: THREE.AdditiveBlending,
  // transparent: true
});

linesMesh = new THREE.LineSegments(geometry, materialLine);
plexusMesh.add(linesMesh);



plexusMesh.scale.set(0.0012, 0.0012, 0.0012);
plexusMesh.position.y = 3.05;
plexusMesh.position.x = 0.52;
plexusMesh.position.z = 0.85;

// gui.add(plexusMesh.position, "x").min(-10).max(10).step(0.01);
// gui.add(plexusMesh.position, "y").min(-10).max(20).step(0.01);
// gui.add(plexusMesh.position, "z").min(-10).max(10).step(0.01);

groupMeshes.add(plexusMesh);

const fisicaObject = new THREE.Group();

const world = new CANNON.World();
world.broadphase = new CANNON.SAPBroadphase(world);
world.allowSleep = true;
world.gravity.set(0, -9.8, 5);
const planeGeo = new THREE.PlaneGeometry(1, 3);
const planeMat = new THREE.ShadowMaterial({
  color: 0x000000,
  side: THREE.DoubleSide,
});
// let positionFloor = new THREE.Vector3()
// gui.add(positionFloor, "x").min(-10).max(10).step(0.01);
// gui.add(positionFloor, "y").min(-10).max(20).step(0.01);
// gui.add(positionFloor, "z").min(-10).max(10).step(0.01);
const cannonDebugger = new CannonDebugger(scene, world);
const planeMesh = new THREE.Mesh(planeGeo, planeMat);
fisicaObject.add(planeMesh);

const defaultMaterial = new CANNON.Material("default");
const defaultContactMaterial = new CANNON.ContactMaterial(
  defaultMaterial,
  defaultMaterial,
  {
    friction: 0.05,
    restitution: 0.8,
  }
);
world.addContactMaterial = defaultContactMaterial;

const planeBody = new CANNON.Body({
  material: defaultContactMaterial,
  type: CANNON.Body.STATIC,
  shape: new CANNON.Box(new CANNON.Vec3(3, 2, 0.002)),
});
planeBody.quaternion.setFromEuler(Math.PI / 2, 0, 0);
planeBody.position.z = -0.3;
planeBody.position.y = 16.5;
planeBody.position.x = 0;
world.addBody(planeBody);

//Sphere

let spawnSpheres = [];
let indexSpawn;
let spheresAmount = 0;
const meshes = [];
const bodies = [];

// let positionSpheres = new THREE.Vector3()
// gui.add(positionSpheres, "x").min(-10).max(10).step(0.01);
// gui.add(positionSpheres, "y").min(-10).max(20).step(0.01);
// gui.add(positionSpheres, "z").min(-10).max(10).step(0.01);

function spawning() {
  for (var i = 0; i < spheresAmount; i++) {
    // const positionSphere = new CANNON.Vec3(positionSpheres.x,  positionSpheres.y, positionSpheres.z);
    const positionSphere = new CANNON.Vec3(0, 17.1, 0);

    const sphereIndex = THREE.Math.randInt(0, socialEmojis.length - 1);
    const sphereTexture = textureLoader.load(socialEmojis[sphereIndex]);
    sphereTexture.encoding = THREE.sRGBEncoding;
    const sphereGeo = new THREE.SphereGeometry(0.1, 15, 15);
    const sphereMat = new THREE.MeshStandardMaterial({
      // color: new THREE.Color(0xffea00).multiplyScalar(15),
      metalness: 0.2,
      roughness: 0.5,
      map: sphereTexture,
    });
    const sphereMesh = new THREE.Mesh(sphereGeo, sphereMat);
    fisicaObject.add(sphereMesh);
    sphereMesh.position.copy(positionSphere);

    const sphereBody = new CANNON.Body({
      material: defaultContactMaterial,
      mass: 0.2,
      shape: new CANNON.Sphere(0.1),
      position: new CANNON.Vec3(
        positionSphere.x,
        positionSphere.y,
        positionSphere.z
      ),
    });
    world.addBody(sphereBody);
    sphereBody.applyForce(
      new CANNON.Vec3(5 * Math.random() * 5, 20, 5),
      sphereBody.position
    );
    meshes.push(sphereMesh);
    bodies.push(sphereBody);
    spawnSpheres.push(sphereMesh);

    let Delete = setInterval(function deleteObjects() {
      fisicaObject.remove(sphereMesh);
      world.remove(sphereBody);
    }, 2500);
  }
}

let callSpawn = setInterval(function () {
  spawning();
}, 150);
// let deleteSpawn = setInterval(function(){
//     deleteObjects()
// },500)

groupMeshes.add(fisicaObject);

gltfLoader.load("/Models/objectsSlider.glb", (modelSlider) => {
  sliderWondar = modelSlider.scene;
  // sliderWondar.position.y = -7.;
  sliderGroup.add(sliderWondar);
  updateAllMaterials();

  for (const child of sliderWondar.children) {
    gsap.to(child.rotation, {
      duration: 2,
      y: -0.15,
      repeat: -1,
      yoyo: true,
      ease: "Sine.easeInOut",
    });
  }
 
  sliderWondar.getObjectByName("Cylinder007_6").material.toneMapped = false
  sliderWondar.getObjectByName("Cylinder007_5").material.toneMapped = false
  sliderWondar.getObjectByName("Cylinder007_6").material.color = new THREE.Color(0xFFFF00).multiplyScalar(20.)
  sliderWondar.getObjectByName("Cylinder007_5").material.color = new THREE.Color(0x00E5FF).multiplyScalar(1.2)
  // INICIO TITULOS
  const divFoguete = document.createElement("div");
  divFoguete.style.position = "absolute";
  divFoguete.classList.add("textoWR");
  divFoguete.innerHTML = "Space Adventure<br>#Instagram<a href=https://www.instagram.com/ar/479880797109601 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/479880797109601 target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/479880797109601 target=_blank class='es botaowork'>haz clic aquí</a>";
  const fogueteLabel = new CSS2DObject(divFoguete);
  fogueteLabel.position.set(0, 7, 0);
  sliderGroup.add(fogueteLabel);

  const divLeon = document.createElement("div");
  divLeon.style.position = "absolute";
  divLeon.classList.add("textoWR");
  divLeon.innerHTML = "Trovo<br>#Instagram <a href=https://www.instagram.com/ar/358907779510043 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/358907779510043 target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/358907779510043 target=_blank class='es botaowork'>haz clic aquí</a>"
  const leonLabel = new CSS2DObject(divLeon);
  leonLabel.position.set(2.9, 7, 0);
  sliderGroup.add(leonLabel);

  const divChampions = document.createElement("div");
  divChampions.style.position = "absolute";
  divChampions.classList.add("textoWR");
  divChampions.innerHTML = "Champions League<br>#Instagram<a href=https://www.instagram.com/ar/1096800007571143/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/1096800007571143/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/1096800007571143/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const championsLabel = new CSS2DObject(divChampions);
  championsLabel.position.set(5.87, 7, 0);
  sliderGroup.add(championsLabel);

  const divFalcoes = document.createElement("div");
  divFalcoes.style.position = "absolute";
  divFalcoes.classList.add("textoWR");
  divFalcoes.innerHTML = "Gerando Falcões<br>#Instagram<a href=https://www.instagram.com/ar/725343958905023/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/725343958905023/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/725343958905023/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const falcoesLabel = new CSS2DObject(divFalcoes);
  falcoesLabel.position.set(8.8, 7, 0);
  sliderGroup.add(falcoesLabel);

  const divBatman = document.createElement("div");
  divBatman.style.position = "absolute";
  divBatman.classList.add("textoWR");
  divBatman.innerHTML = "Panini Fun<br>#Instagram <a href=https://www.instagram.com/ar/3125748707699013/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/3125748707699013/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/3125748707699013/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const batmanLabel = new CSS2DObject(divBatman);
  batmanLabel.position.set(11.75, 7, 0);
  sliderGroup.add(batmanLabel);

  const divNeymar = document.createElement("div");
  divNeymar.style.position = "absolute";
  divNeymar.classList.add("textoWR");
  divNeymar.innerHTML = "Neymar Jr<br>#Instagram <a href=https://www.instagram.com/ar/319238912554808 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/319238912554808 target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/319238912554808 target=_blank class='es botaowork'>haz clic aquí</a>"
  const neymarLabel = new CSS2DObject(divNeymar);
  neymarLabel.position.set(14.65, 7, 0);
  sliderGroup.add(neymarLabel);

  const divTotvs = document.createElement("div");
  divTotvs.style.position = "absolute";
  divTotvs.classList.add("textoWR");
  divTotvs.innerHTML = "Totvs<br>#Instagram <a href=https://www.instagram.com/ar/640614663811834/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/640614663811834/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/640614663811834/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const totvsLabel = new CSS2DObject(divTotvs);
  totvsLabel.position.set(17.65, 7, 0);
  sliderGroup.add(totvsLabel);

  const divIfood = document.createElement("div");
  divIfood.style.position = "absolute";
  divIfood.classList.add("textoWR");
  divIfood.innerHTML = "iFood<br>#Instagram<a href=https://www.instagram.com/ar/440711357751997/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/440711357751997/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/440711357751997/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const ifoodLabel = new CSS2DObject(divIfood);
  ifoodLabel.position.set(20.6, 7, 0);
  sliderGroup.add(ifoodLabel);

  const divSemParar = document.createElement("div");
  divSemParar.style.position = "absolute";
  divSemParar.classList.add("textoWR");
  divSemParar.innerHTML = "Sem Parar<br>#Instagram<a href=https://www.instagram.com/ar/714888132550263/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/714888132550263/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/714888132550263/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const semPararLabel = new CSS2DObject(divSemParar);
  semPararLabel.position.set(23.5, 7, 0);
  sliderGroup.add(semPararLabel);

  const divGol = document.createElement("div");
  divGol.style.position = "absolute";
  divGol.classList.add("textoWR");
  divGol.innerHTML = "Gol<br>#Instagram<a href=https://www.instagram.com/ar/673008296721120/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/673008296721120/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/673008296721120/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const golLabel = new CSS2DObject(divGol);
  golLabel.position.set(26.5, 7, 0);
  sliderGroup.add(golLabel);

  const divGillete = document.createElement("div");
  divGillete.style.position = "absolute";
  divGillete.classList.add("textoWR");
  divGillete.innerHTML = "Gillete<br>#Instagram<a href=https://www.instagram.com/ar/793631115196817/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.instagram.com/ar/793631115196817/ target=_blank class='en botaowork'>click here</a> <a href=https://www.instagram.com/ar/793631115196817/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const gilleteLabel = new CSS2DObject(divGillete);
  gilleteLabel.position.set(29.4, 7, 0);
  sliderGroup.add(gilleteLabel);

  const divAnitta = document.createElement("div");
  divAnitta.style.position = "absolute";
  divAnitta.classList.add("textoWR");
  divAnitta.innerHTML = "Anitta<br>#TikTok<a href=https://vm.tiktok.com/ZMNbVjhpE/ target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://vm.tiktok.com/ZMNbVjhpE/ target=_blank class='en botaowork'>click here</a> <a href=https://vm.tiktok.com/ZMNbVjhpE/ target=_blank class='es botaowork'>haz clic aquí</a>"
  const anittaLabel = new CSS2DObject(divAnitta);
  anittaLabel.position.set(32.35, 7, 0);
  sliderGroup.add(anittaLabel);

  const divPiracanjuba = document.createElement("div");
  divPiracanjuba.style.position = "absolute";
  divPiracanjuba.classList.add("textoWR");
  divPiracanjuba.innerHTML = "Piracanjuba<br>#TikTok<a href=https://www.tiktok.com/sticker/Desafio-PiraWhey-1968506 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.tiktok.com/sticker/Desafio-PiraWhey-1968506 target=_blank class='en botaowork'>click here</a> <a href=https://www.tiktok.com/sticker/Desafio-PiraWhey-1968506 target=_blank class='es botaowork'>haz clic aquí</a>"
  const piracanjubaLabel = new CSS2DObject(divPiracanjuba);
  piracanjubaLabel.position.set(35.32, 7, 0);
  sliderGroup.add(piracanjubaLabel);

  const divNetflix = document.createElement("div");
  divNetflix.style.position = "absolute";
  divNetflix.classList.add("textoWR");
  divNetflix.innerHTML = "Netflix<br>#TikTok<a href=https://www.tiktok.com/sticker/A-Escola-do-Bem-e-do-Mal-Netflix-4504390 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.tiktok.com/sticker/A-Escola-do-Bem-e-do-Mal-Netflix-4504390 target=_blank class='en botaowork'>click here</a> <a href=https://www.tiktok.com/sticker/A-Escola-do-Bem-e-do-Mal-Netflix-4504390 target=_blank class='es botaowork'>haz clic aquí</a>"
  const netflixLabel = new CSS2DObject(divNetflix);
  netflixLabel.position.set(38.25, 7, 0);
  sliderGroup.add(netflixLabel);

  const divGlobo = document.createElement("div");
  divGlobo.style.position = "absolute";
  divGlobo.classList.add("textoWR");
  divGlobo.innerHTML = "Globo<br>#TikTok<a href=https://www.tiktok.com/sticker/ToNoGloboplay-5013165 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.tiktok.com/sticker/ToNoGloboplay-5013165 target=_blank class='en botaowork'>click here</a> <a href=https://www.tiktok.com/sticker/ToNoGloboplay-5013165 target=_blank class='es botaowork'>haz clic aquí</a>"
  const globoLabel = new CSS2DObject(divGlobo);
  globoLabel.position.set(41.15, 7, 0);
  sliderGroup.add(globoLabel);

  const divPulse = document.createElement("div");
  divPulse.style.position = "absolute";
  divPulse.classList.add("textoWR");
  divPulse.innerHTML = "Pulse<br>#SnapChat<a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=0946adae96864b0382ba42b457fb90fa&metadata=01 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=0946adae96864b0382ba42b457fb90fa&metadata=01 target=_blank class='en botaowork'>click here</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=0946adae96864b0382ba42b457fb90fa&metadata=01 target=_blank class='es botaowork'>haz clic aquí</a>"
  const pulseLabel = new CSS2DObject(divPulse);
  pulseLabel.position.set(44.12, 7, 0);
  sliderGroup.add(pulseLabel);

  const divThumsup = document.createElement("div");
  divThumsup.style.position = "absolute";
  divThumsup.classList.add("textoWR");
  divThumsup.innerHTML = "ThumsUP<br>#SnapChat<a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=27162d43aba24616a83014574713fde5&metadata=01 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=27162d43aba24616a83014574713fde5&metadata=01 target=_blank class='en botaowork'>click here</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=27162d43aba24616a83014574713fde5&metadata=01 target=_blank class='es botaowork'>haz clic aquí</a>"
  const thumsUpLabel = new CSS2DObject(divThumsup);
  thumsUpLabel.position.set(47.05, 7, 0);
  sliderGroup.add(thumsUpLabel);

  const divYippee = document.createElement("div");
  divYippee.style.position = "absolute";
  divYippee.classList.add("textoWR");
  divYippee.innerHTML = "Yippee<br>#SnapChat<a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d9653158c8db498c8858aadad2291c91&metadata=01 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d9653158c8db498c8858aadad2291c91&metadata=01 target=_blank class='en botaowork'>click here</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d9653158c8db498c8858aadad2291c91&metadata=01 target=_blank class='es botaowork'>haz clic aquí</a>"
  const yippeeLabel = new CSS2DObject(divYippee);
  yippeeLabel.position.set(49.999, 7, 0);
  sliderGroup.add(yippeeLabel);

  const divCricket = document.createElement("div");
  divCricket.style.position = "absolute";
  divCricket.classList.add("textoWR");
  divCricket.innerHTML = "ThumsUP Cricket<br>#SnapChat<a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=c9ccca656e744756a88837df88aaa82d&metadata=01 target=_blank class='pt-br botaowork'>clique aqui</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=c9ccca656e744756a88837df88aaa82d&metadata=01 target=_blank class='en botaowork'>click here</a> <a href=https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=c9ccca656e744756a88837df88aaa82d&metadata=01 target=_blank class='es botaowork'>haz clic aquí</a>"
  const cricketLabel = new CSS2DObject(divCricket);
  cricketLabel.position.set(52.99, 7, 0);
  sliderGroup.add(cricketLabel);

  //FIM TITULOS

  sliderWondar.scale.set(0.5, 0.5, 0.5);


});

// Material
const material = new THREE.MeshPhysicalMaterial({
  color: parameters.materialColor,
  roughness: 0,
  metalness: 0.5,
});

// Objects

const sliderGroup = new THREE.Group();

scene.add(sliderGroup);

scene.add(groupMeshes);

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
directionalLight.position.set(1, 1, 0);
scene.add(directionalLight);
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update effect composer
  effectComposer.setSize(sizes.width, sizes.height);
  effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

let labelRenderer;
// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = 6; // padrao 6 debug 20
cameraGroup.add(camera);

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  powerPreference: "high-performance",
});
renderer.shadowMap.enabled = false;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = false;
renderer.clearDepth();
renderer.setClearColor(0x000000);
renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.toneMappingExposure = Math.pow( 0.9, 4.0 )
renderer.toneMappingExposure = 3;

labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(window.innerWidth, window.innerHeight);
//   labelRenderer.domElement.style.position = "absolute";
labelRenderer.domElement.style.top = "0px";
labelRenderer.domElement.style.position = "absolute";
document.body.appendChild(labelRenderer.domElement);

/**
 * Post processing
 */
const renderTarget = new THREE.WebGLRenderTarget(800, 600, {
  type: THREE.HalfFloatType,
  encoding: THREE.sRGBEncoding,
  minFilter: THREE.NearestFilter,
  magFilter: THREE.NearestFilter,
  samples: 2,
  // toneMappingExposure: 3
});

// Render pass
const renderPass = new RenderPass(scene, camera);

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
effectComposer.setSize(sizes.width, sizes.height);

// Antialias pass
if (renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2) {
  const smaaPass = new SMAAPass();
  effectComposer.addPass(smaaPass);
}

// Unreal Bloom pass
const unrealBloomPass = new UnrealBloomPass(
  new THREE.Vector2(sizes.width, sizes.height),
  1.5,
  0.4,
  0.85
);

unrealBloomPass.strength = 0.4;
unrealBloomPass.radius = 1;
unrealBloomPass.threshold = 1.1;
// unrealBloomPass.renderToScreen = false
effectComposer.addPass(renderPass);
effectComposer.addPass(unrealBloomPass);

/**
 * Scroll
 */

let select = (selector) => {
  return document.getElementById(selector);
};

let next_btn = select("desce");
let prev_btn = select("sobe");

let setaEsq = select("setaesq");
let setaDir = select("setadir");

let tl = gsap.timeline({
  paused: true,
  defaults: { duration: 1, ease: "power2.inOut" },
});
// let words = "";

function floorButtonChange(currentSlide) {
  logoWondar.style.marginTop = currentSlide * 20 * -1 + "vw";
}

tl.addPause("+=0", floorButtonChange, [0]);
tl.from(groupMeshes.position, {});
tl.from(sliderGroup.position, {}, "-=1.");
tl.addPause("+=0", floorButtonChange, [1]);
tl.to(groupMeshes.position, { y: -3.35 }, "-=1.");
tl.to(sliderGroup.position, { y: -3.35 }, "-=1.");
tl.to("#p1", { innerText: "what we do" }, "-=1."); // altera o texto de uma ID

tl.from(groupMeshes.position, {});
tl.from(sliderGroup.position, {}, "-=1.");
tl.addPause("+=0", floorButtonChange, [2]);
tl.to(groupMeshes.position, { y: -7.3 }, "-=1.");
tl.to(sliderGroup.position, { y: -7.3 }, "-=1.");
tl.to("#p1", { innerText: "social ar" }, "-=1."); // altera o texto de uma ID

// tl.add( function(){ document.getElementById('setasid').classList.add('foradatela');})
tl.to(".setas", { duration: 0.5, y: "55vh" }, "-=1.");
tl.from(groupMeshes.position, {});
tl.from(sliderGroup.position, {}, "-=1.");
tl.addPause("+=0", floorButtonChange, [3]);
//  tl.add( function(){ document.getElementById('setasid').classList.remove('foradatela');})
tl.to(groupMeshes.position, { y: -10.3 }, "-=1.");
tl.to(sliderGroup.position, { y: -10.3 }, "-=1.");
tl.to(".setas", { duration: 0.5, y: 0 }, "-=1.");
// tl.to('#p2', { innerText: "" }, "-=1.") // altera o texto de uma ID
tl.to("#p1", { innerText: "web ar" }, "-=1."); // altera o texto de uma ID
// tl.to(".maozinha", {right: "2vw"}, "-=1.")

//  tl.add( function(){ document.getElementById('setasid').classList.add('foradatela');})

tl.from(groupMeshes.position, {});
tl.from(sliderGroup.position, {}, "-=1.");
tl.addPause("+=0", floorButtonChange, [4]);
tl.to(groupMeshes.position, { y: -13.8 }, "-=1.");
tl.to(sliderGroup.position, { y: -13.8 }, "-=1.");
tl.to("#p1", { innerText: "ar ecommerce" }, "-=1."); // altera o texto de uma ID

tl.from(groupMeshes.position, {});
tl.from(sliderGroup.position, {}, "-=1.");
tl.addPause("+=0", floorButtonChange, [5]);
// tl.to(".maozinha", {right: "-50vw"}, "-=1.")
tl.to(groupMeshes.position, { y: -17 }, "-=1.");
tl.to(sliderGroup.position, { y: -17 }, "-=1.");
tl.to("#p1", { innerText: "ar ads" }, "-=1."); // altera o texto de uma ID

// tl.from(groupMeshes.position, {});
// tl.from(sliderGroup.position, {}, "-=1.");

let tlSlider = gsap.timeline({
  paused: false,
  defaults: { duration: 0.4, ease: "Power2.easeInOut" },
});


// tlSlider.from(groupMeshes.position, {})
//foguete para leon
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -2.9 });
// tlSlider.to('#p2', { innerText: "Leon" }) // altera o texto de uma ID

//leon para bola
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -5.87 });
// tlSlider.to('#p2', { innerText: "Champions League" }) // altera o texto de uma ID

//bola para falcao
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -8.8 });
// tlSlider.to('#p2', { innerText: "Gerando Falcões" }) // altera o texto de uma ID

//falcao para batman
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -11.75 });
// tlSlider.to('#p2', { innerText: "Batman" }) // altera o texto de uma ID

//batman para neymar
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -14.65 });
// tlSlider.to('#p2', { innerText: "Neymar" }) // altera o texto de uma ID

//neymar para totvs
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -17.65 });
// tlSlider.to('#p2', { innerText: "TOTVS" }) // altera o texto de uma ID

//totvs para ifood
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -20.6 });
// tlSlider.to('#p2', { innerText: "iFood" }) // altera o texto de uma ID

//ifood para sem parar
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -23.5 });
// tlSlider.to('#p2', { innerText: "Sem Parar" }) // altera o texto de uma ID

//sem parar para gol
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -26.5 });
// tlSlider.to('#p2', { innerText: "Gol" }) // altera o texto de uma ID

//gol para bigode
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -29.4 });
// tlSlider.to('#p2', { innerText: "Gillete" }) // altera o texto de uma ID

//bigode para anitta
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -32.35 });
// tlSlider.to('#p2', { innerText: "Anitta" }) // altera o texto de uma ID

//anitta para piracanjuba
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -35.32 });
// tlSlider.to('#p2', { innerText: "Piracanjuba" }) // altera o texto de uma ID

//piracanjuba para netflix
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -38.25 });
// tlSlider.to('#p2', { innerText: "Netflix" }) // altera o texto de uma ID

//netflix para globo
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -41.15 });
// tlSlider.to('#p2', { innerText: "Globo" }) // altera o texto de uma ID

//globo para pulse
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -44.12 });
// tlSlider.to('#p2', { innerText: "Pulse" }) // altera o texto de uma ID

//pulse para thumsup
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -47.05 });
// tlSlider.to('#p2', { innerText: "ThumsUp" }) // altera o texto de uma ID

//thumsup para yippee
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -49.999 });
// tlSlider.to('#p2', { innerText: "Yippee" }) // altera o texto de uma ID

//yippee para cricket
tlSlider.from(sliderGroup.position, {});
tlSlider.addPause();
tlSlider.to(sliderGroup.position, { x: -52.99 });
// tlSlider.to('#p2', { innerText: "Cricket" }) // altera o texto de uma ID

tlSlider.from(groupMeshes.position, {});

/**
 * Ação de botão/swipe próximo
 */
function slideNext() {
  if (tl.isActive()) {
    return;
  }

  tl.play();
}

/**
 * Ação de botão/swipe anterior
 */
function slidePrev() {
  if (tl.isActive()) {
    return;
  }
  return tl.reverse();
}

const boxFechar = function(){
  gsap.to(".box-texto", {
    x: "-100vw",
    duration: 0.5,
    ease: "Power1.easeInOut"
  })
}

const boxAbrir = function(){
  gsap.to(".box-texto", {
    x: "0",
    duration: 0.5,
    ease: "Power1.easeInOut"
  })
}

next_btn.addEventListener("click", slidePrev);
next_btn.addEventListener("click", boxFechar);
prev_btn.addEventListener("click", slideNext);
prev_btn.addEventListener("click", boxFechar);
document.addEventListener("swiped-up", slidePrev);
document.addEventListener("swiped-down", slideNext);

document.querySelectorAll(".fechar").forEach((el) => {
  el.addEventListener("click", () => {
    setTimeout(function(){
      el.parentElement.classList.add("d-none", "oculto");
    }, 500)
    boxFechar()
  });
});

document.querySelectorAll(".botaoInfo").forEach(function ($obj) {
  $obj.addEventListener("click", function () {
    let classe = $obj.classList[1].replace("botao", "texto");
    document.querySelector("." + classe).classList.remove("d-none");
    boxAbrir()
  });
});

setaEsq.addEventListener("click", () => tlSlider.reverse());
setaDir.addEventListener("click", () => tlSlider.play());


/**
 * Cursor
 */
const cursor = {};
cursor.x = 0;
cursor.y = 0;

// window.addEventListener('move', (_event) => {
//     cursor.x = _event.clientX / sizes.width - 0.5
//     cursor.y = _event.clientY / sizes.height - 0.5
// })

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Go through each point

  //Plexus

  let vertexpos = 0;
  let colorpos = 0;
  let numConnected = 0;
  for (let i = 0; i < particleCount; i++) particlesData[i].numConnections = 0;

  for (let i = 0; i < particleCount; i++) {
    // get the particle
    const particleData = particlesData[i];

    particlePositions[i * 3] += particleData.velocity.x;
    particlePositions[i * 3 + 1] += particleData.velocity.y;
    particlePositions[i * 3 + 2] += particleData.velocity.z;

    if (
      particlePositions[i * 3 + 1] < -rHalf ||
      particlePositions[i * 3 + 1] > rHalf
    )
      particleData.velocity.y = -particleData.velocity.y;

    if (particlePositions[i * 3] < -rHalf || particlePositions[i * 3] > rHalf)
      particleData.velocity.x = -particleData.velocity.x;

    if (
      particlePositions[i * 3 + 2] < -rHalf ||
      particlePositions[i * 3 + 2] > rHalf
    )
      particleData.velocity.z = -particleData.velocity.z;

    if (
      effectController.limitConnections &&
      particleData.numConnections >= effectController.maxConnections
    )
      continue;

    // Check collision
    for (let j = i + 1; j < particleCount; j++) {
      const particleDataB = particlesData[j];
      if (
        effectController.limitConnections &&
        particleDataB.numConnections >= effectController.maxConnections
      )
        continue;

      const dx = particlePositions[i * 3] - particlePositions[j * 3];
      const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1];
      const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2];
      const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

      if (dist < effectController.minDistance) {
        particleData.numConnections++;
        particleDataB.numConnections++;

        const alpha = 1.0 - dist / effectController.minDistance;

        positions[vertexpos++] = particlePositions[i * 3];
        positions[vertexpos++] = particlePositions[i * 3 + 1];
        positions[vertexpos++] = particlePositions[i * 3 + 2];

        positions[vertexpos++] = particlePositions[j * 3];
        positions[vertexpos++] = particlePositions[j * 3 + 1];
        positions[vertexpos++] = particlePositions[j * 3 + 2];

        colors[colorpos++] = alpha;
        colors[colorpos++] = alpha;
        colors[colorpos++] = alpha;

        colors[colorpos++] = alpha;
        colors[colorpos++] = alpha;
        colors[colorpos++] = alpha;

        numConnected++;
      }
    }
  }

  linesMesh.geometry.setDrawRange(0, numConnected * 2);
  linesMesh.geometry.attributes.position.needsUpdate = true;
  linesMesh.geometry.attributes.color.needsUpdate = true;

  pointCloud.geometry.attributes.position.needsUpdate = true;

  // const parallaxX = cursor.x * 0.5
  // const parallaxY = - cursor.y * 0.5
  // cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
  // cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

  //physic

  world.step(1 / 60, deltaTime, 3);
  cannonDebugger.update();
  planeMesh.position.copy(planeBody.position);
  planeMesh.quaternion.copy(planeBody.quaternion);

  for (let i = 0; i < meshes.length; i++) {
    meshes[i].position.copy(bodies[i].position);
    meshes[i].quaternion.copy(bodies[i].quaternion);
  }

  // Update Actions
  if (mixer) {
    mixer.update(deltaTime);
  }
  // Update Fisica
  if (tl.progress() >= 0.8) {
    spheresAmount = 1;
  } else {
    spheresAmount = 0;
  }
  // Render
  renderer.render(scene, camera);
  labelRenderer.render(scene, camera);
  effectComposer.render();
  
  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
